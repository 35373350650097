import { Box, Card, CardBody, CardFooter, CardHeader, Flex, HStack, Heading, Image, Text, VStack, useBreakpointValue } from '@chakra-ui/react'
import React,{useEffect} from 'react'
import TopMainContent from '../../common/dasboardTopCards'
import { MdOutlineNotifications, MdOutlineVolunteerActivism, MdOutlineWorkOutline } from 'react-icons/md'
import { Link, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NavState } from '../../features/navigation/model/navinterface'


import dashboardIcon from '../../images/icon.png'
import { actions } from '../../features/navigation/navSlice'
import Sidebar from '../../common/Sidebar'
import { useAppDispatch, useAppSelector } from '../../features/hooks/hooks'
import { getVolunteerAction } from '../../features/volunteer/VolunteerSlice'
import { DashboardInfoAction } from '../../features/dashboardInfo/DashboardInfoSlice'
import { getAppliedJobsAction } from '../../features/appliedJobs/appliedSlice'

const Dashboard  = () => {
  const initial  = useSelector((state:{nav:NavState})=>state.nav.initial);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const dashboarInfo = useAppSelector(state=>state.dashboardInfo)
  // const volunteer = useAppSelector(state=>state.volunteer)
  // const appliedJobs = useAppSelector((state)=>state.appliedJobs)


  return (
    <Box display={{md:'flex',sm:"block"}} pos={{base:'sticky',md:"relative"}}>
              <Sidebar />
            {/* <Box display={{base:"none",md:"block",xl:"block"}}>
              
            </Box> */}
          
          <Box flex={{md:5}} position='relative' display='flex' flexDirection='column' ml={3} h={{md:'100vh'}}
              overflowY='hidden'
              css={{
                '&::-webkit-scrollbar': {
                  width: '0px',
                },
              }}>
              <TopMainContent/>
              

              {/* the is part is for the top card */}
                <HStack display={{md:"flex", base:"none"}} justifyContent='space-evenly'mt={2} mb={2}>
                  <DashboardCards path="/dashboard/appliedjobs" name='Applied Jobs' value={dashboarInfo.numberOfAppliedJobs} icon={ <MdOutlineWorkOutline/>}/>
                  <DashboardCards path="/dashboard/volunteer" name='Volunteering' value={dashboarInfo.numberOfVolunteer} icon={<MdOutlineVolunteerActivism/>}/>
                  <DashboardCards path="/dashboard/internship" name='Internship' value={dashboarInfo.numberOfInternship} icon={ <MdOutlineWorkOutline/>}/>
                  <DashboardCards path="/dashboard" name='Notifications' value={dashboarInfo.numberOfInternship} icon={<MdOutlineNotifications/>}/>
                </HStack>
                {/* {(initial&&!isMobile)&&<Graph/>} */}

              {/* THIS IS FOR DISPLAYING THE OUTPUT OF THE INDIVIDUAL SCREENS */}

                <Outlet/>  
            </Box>
    </Box>
  )
}

export default Dashboard


type cardProps={
  name:string,
  value:string|number
  icon:React.ReactNode,
  path:string
}

 export const DashboardCards = ({name,value,icon,path}:cardProps) => {
  const navlink = useSelector((state:{nav:NavState})=>state.nav.activeLink)
  const dispatch = useDispatch()
  return (
  // <Box  w='25%' onClick={()=>name!='Notifications'?dispatch(actions.changeLinkState(name)):dispatch(actions.changeLinkState("Dashboard"))}>
  //  <Link to={path}>
  //     <Card    justifyContent='center' >
        
  //       <Box>
  //           <Flex justifyContent='space-between'alignItems='center' pb={2} pl={2} pr={2}  >
  //           <HStack  >
  //             <Box pt={2}>{icon}</Box>
  //             <Text >{value}</Text>
  //             <Text>{name}</Text>
  //           </HStack>
    
  //           <Box>
              
  //            <Image src={dashboardIcon}/>
  //           </Box>
  //           </Flex>
  //       </Box>  
  //    </Card>
  //  </Link>
  //   </Box>

  <Box  h={{base:"10%",md:"15vh",xl:"12vh","2xl": "9vh"}} w='25%' onClick={()=>name!='Notifications'?dispatch(actions.changeLinkState(name)):dispatch(actions.changeLinkState("Dashboard"))}>
   <Link to={path}>
      <Card    h={{base:"10%",md:"100%",xl:"20"}} justifyContent='center' alignItems='center' >
        
        <Box h={{base:"10%",md:"100%"}}  display='flex' justifyContent='space-between' alignItems='center'>
            <Flex  pb={2} pl={2} pr={2} w='inherit' justifyContent='space-between' alignItems='center' >
            <Box   h={{base:"10%",md:"100%"}} display='flex' justifyContent='center' alignItems='center'>
              <Box pr={2}>{icon}</Box>
              <Text fontSize='20px' pr='10px'>{name}</Text>
              <Text fontSize={20} >{value}</Text>
            </Box>
    
            {/* <Box>
              <Image src={dashboardIcon}/>
            </Box> */}
            </Flex>
        </Box>  
     </Card>
   </Link>
    </Box>
  )
}


  