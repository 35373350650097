import { Box,   Button,   Card,   Flex, Heading, Text, VStack, baseTheme, useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlineArrowBackIos, MdOutlineBookmark, MdOutlineLink, MdOutlineMyLocation } from 'react-icons/md'
import { type } from 'os'

import image from '../../images/person.jpg';
import { useDispatch, useSelector } from 'react-redux'
import { NavState } from '../../features/navigation/model/navinterface'
import { actions } from '../../features/navigation/navSlice'
import { useAppDispatch, useAppSelector } from '../../features/hooks/hooks'
import { InternshipAction, getInternsAction } from '../../features/internship/InternshipSlice'
import { DashboardInfoAction } from '../../features/dashboardInfo/DashboardInfoSlice';
import { applicationResponseAction } from '../../features/applicationResponse/applicationSlice';
 export const Internship = () => {
  const state = useSelector((state:{nav:NavState})=>state.nav)
  const isMobile = useBreakpointValue({ base: true, md: false });
  const internSelector = useAppSelector((state)=>state.internships)
  const internDispatch = useAppDispatch()
  

  useEffect(()=>{
    internDispatch(getInternsAction())
  })
  return (

    <Box w={{base:'97%',md:'100%'}} mb='15px'  >
    {internSelector.interns.length!==0?
    
    <Box   w={{base:'97%',md:'100%'}} mb='15px'   >
        
        {
          !isMobile?
          <Content show={true} showdetails={true}/>:
          <Content show={!state.mobileIntLink} showdetails={state.mobileIntLink}/>
        }
           
        
      </Box>:
    <Card w={{base:'98%',md:'100%'}} h='98vh' display='flex' justifyContent='center' alignItems="center">Empty</Card>
  }
</Box>
      
    
  )
}

type textProps={
  name:string,
  value:string
}

export const ApplicationText = ({name,value}:textProps) => {
  return (
    <Box display='flex'  alignItems='center'>
        <Text flex={1}>{name}</Text>
        <Text flex={2}>{value}</Text>
    </Box>
  )
}







type applicantProps={
  name:string,
  email:string,
  date:string,
  id:string

}

 export const ListOfInterns = ({name,email,date,id}:applicantProps) => {
  const mobilestate = useSelector((state:{nav:NavState})=>state.nav.mobileIntLink)
  const dispatch = useAppDispatch()
  return (
   <Card h='15vh' w={{base:"94vw",md:'97%'}} p={2} onClick={()=>{dispatch(InternshipAction.changeActiveIntern({name,email}));dispatch(actions.mobileBackArrowInternship(mobilestate))}}>
      <Text>Name:  {name}</Text>
      <Text>email: {email}</Text>
      <Text>Date:  {date}</Text>
   </Card>
  )
}



type contentProps={
  show:Boolean
  showdetails:Boolean
}
export const Content = ({show,showdetails}:contentProps) => {
  const state = useSelector((state:{nav:NavState})=>state.nav)
  
  const dispatch = useAppDispatch()
  const isMobile = useBreakpointValue({ base: true, md: false });

  const internDispatch = useAppDispatch()
  const internSelector = useAppSelector((state)=>state.internships)

  const applicationResponse =(data:{name:string,email:string,status:string})=>{
    dispatch(applicationResponseAction(data))
  }
  
  useEffect(
    ()=>{
       internDispatch(getInternsAction())
       dispatch(DashboardInfoAction.numberInternship(internSelector.interns.length))

    }
  )

  return (
    <Box  display={{base:"block",md:'flex'}} h={{base:"98%",md:'77vh'}} >

{
  show&&
    <Card h='8vh' w='inherit' mb='10px' mt='2px' >
      <Box h='inherit' w="80%" pl='8px' display={{base:"flex",md:"none"}} justifyContent='space-between'alignItems='center'>
      <MdOutlineArrowBackIos  onClick={()=>dispatch(actions.mobileBackArrowInternship(state.mobileIntLink))}/>
      <Text>Internship Applications</Text>
      </Box>
    </Card>
  }
    {/* THIS MANAGE THE LIST OF APPLICANT FOR BOTH MOBILE AND PC */}
    {show&&
    <VStack flex={1}
    h={{ base:"89vh",xl:"inherit",'2xl':"85vh"}}
    
    overflowY='auto'
    css={{
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    }} 

  >
     {/* THIS IS FOR MOBILE VIEW THE APPBAR */}
 
    {
    internSelector.interns.map(item=>(
    <ListOfInterns key={item.email} id={item.email}name={item.name} email={item.email} date={item.startDate}/>
    
    ))
  }
  </VStack>}
  {/* THIS IS FOR MOBILE VIEW THE APPBAR */}
  {showdetails&&
    <Card h='8vh' w='inherit' mb='10px' mt='2px' >
      <Box h='inherit' w="60%" pl='8px' display={{base:"flex",md:"none"}} justifyContent='space-between'alignItems='center'>
      <MdOutlineArrowBackIos  onClick={()=>dispatch(actions.mobileBackArrowInternship(state.mobileIntLink))}/>
      <Text>Internship</Text>
      </Box>
    </Card>
  }

  {/* THIS PART MANAGESE THE DISPLAY CONTENT VIEW OF ALL THE APPLICAANT FOR BOTH MOBILE AND PC */}
  


  {
  showdetails&&
  <Box flex={3} display={{base:"block",md:"block"}}>
  <Card h={{base:"90vh",xl:"100%",'2xl':"85vh"}} p={5} 
   overflowY='auto'
   css={{
     '&::-webkit-scrollbar': {
       width: '0px',
     },
   }} 
  >
    {
      internSelector.interns.map(item=>(
        (item.email===internSelector.active.email&& item.name===internSelector.active.name)&& <Box key={item.email} >
        <Heading  fontSize='18'> An application for Internship</Heading>
        <Text variant='p' pb="2" pt='2'>Personal:</Text>
        <ApplicationText name=' Name:'     value= {item.name} />
        <ApplicationText name='Email:'   value={item.email} />
        <ApplicationText name='Address' value={item.address} />
        <ApplicationText name='Tel:' value= {item.phone} />
        <ApplicationText name='Town:' value= {item.town} />
        <Text variant='p' pb="2" pt='2'>Education:</Text>
        <ApplicationText name='School:' value= {item.school} />
        {/* <Text>Address: {item} </Text> */}
        
        <object data="" width="97%"  height={isMobile?"50%":'97%'}  type='application/pdf'/>
        
        <Flex mt="2vh" mb='1rem' justifyContent='space-around' w='100%'>
        <Button onClick={()=>applicationResponse({name:item.name!,email:item.email!,status:"No"})} >Reject</Button>
        <Button onClick={()=>applicationResponse({name:item.name!,email:item.email!,status:"Yes"})}>Accept</Button>
      </Flex> 
      <Box  h={4}/> 
    </Box>
       
      )
         )
    }
    {
      internSelector.active===null&&
      <Box display='flex' justifyContent='center' alignItems='center' h='inherit'>
          <Heading fontSize={16} >EMPTY PLEASE CLICK ON ONE</Heading>
      </Box>
    }

   
   
       

  </Card>
  </Box>}
</Box>
  )
}











 





