import { createSlice } from "@reduxjs/toolkit";
import { DashboardInfo } from "./model/dashboardInfo";


const initialState:DashboardInfo={
    numberOfunreadJobs: 0,
    numberOfPostedJobs: 0,
    numberOfAcceptedJobs: 0,
    numberOfVolunteer: 0,
    numberOfAppliedJobs: 0,
    numberOfInternship: 0
}

const DashboardInfoSlice = createSlice({
    name:"dashboardInfo",
    initialState,
    reducers:{

        incrementReadJobs(state){
            state.numberOfunreadJobs +=1
        },

        incrementPostJobs(state){
            state.numberOfPostedJobs +=1
        },

        incrementAcceptJobs(state){
                state.numberOfAcceptedJobs +=1
        },

        
        numberInternship(state,action){
            state.numberOfInternship = action.payload
        },

        numberAppliedJobs(state,action){
            state.numberOfAppliedJobs= action.payload
        },
        numberVolunter(state,action){
            state.numberOfVolunteer=action.payload
        }

    }

})

export const DashboardInfoAction = DashboardInfoSlice.actions
export default DashboardInfoSlice.reducer