import { Box,   Button,   Card,   Flex, Heading, Text, VStack, baseTheme, useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlineArrowBackIos, MdOutlineBookmark, MdOutlineLink, MdOutlineMyLocation } from 'react-icons/md'
import { type } from 'os'

import { useDispatch, useSelector } from 'react-redux'
import { NavState } from '../../features/navigation/model/navinterface'
import { actions } from '../../features/navigation/navSlice'
import { appliedAction, getAppliedJobsAction } from '../../features/appliedJobs/appliedSlice'
import { useAppDispatch, useAppSelector } from '../../features/hooks/hooks'
import { DashboardInfoAction } from '../../features/dashboardInfo/DashboardInfoSlice'
import ApiConfig from '../../features/api'
import { applicationResponseAction } from '../../features/applicationResponse/applicationSlice'

// const user = localStorage.setItem("user","Betandclifff")

 export const Applied_Jobs = () => {
  
  const state = useSelector((state:{nav:NavState})=>state.nav)
  const isMobile = useBreakpointValue({ base: true, md: false });
  const appliedJobs = useAppSelector((state)=>state.appliedJobs)
  const dispatche = useAppDispatch()

  useEffect(()=>{
    dispatche(getAppliedJobsAction())
    dispatche(DashboardInfoAction.numberAppliedJobs(appliedJobs.applicants.length))

    console.log("from the console:",appliedJobs.applicants)

  },[getAppliedJobsAction])

  return (

        
      <Box w={{base:'97%',md:'100%'}} mb='15px'  >
          {appliedJobs.applicants.length!==0?
          
          <Box   w={{base:'97%',md:'100%'}} mb='15px'   >
              {/* <Box> the length is{appliedJobs.applicants.length}</Box> */}
          {
            !isMobile?
            <Content show={true} showdetails={true}/>:
            
            <Content show={!state.mobileAppLink} showdetails={state.mobileAppLink}/>
          }
       
    
      </Box>:
      <Card w={{base:'98%',md:'100%'}} h='98vh' display='flex' justifyContent='center' alignItems="center">Empty</Card>
        }
      </Box>
    
  )
}

type textProps={
  name?:string,
  value?:string|number
}

export const ApplicationText = ({name,value}:textProps) => {
  return (
    <Box display='flex'  alignItems='center'>
        <Text flex={1}>{name}</Text>
        <Text flex={2}>{value}</Text>
    </Box>
  )
}







type applicantProps={
  name?:string,
  position?:string,
  date?:string,
  email?:string

}

 export const ListOfApplicant = ({name,position,date,email}:applicantProps) => {
  const mobilestate = useSelector((state:{nav:NavState})=>state.nav.mobileAppLink)
  const dispatch = useAppDispatch()
  return (
   <Card h='15vh' w={{base:"94vw",md:'97%'}} p={2} onClick={()=>{dispatch(appliedAction.changeEmail({name,email}));dispatch(actions.mobileBackArrowApplied(mobilestate))}}>
      <Text>Name:  {name}</Text>
      <Text>Position: {position}</Text>
      <Text>Date:  {date}</Text>
   </Card>
  )
}



type contentProps={
  show:Boolean
  showdetails:Boolean
}
export const Content = ({show,showdetails}:contentProps) => {
  const state = useSelector((state:{nav:NavState})=>state.nav)
  const dispatch = useAppDispatch()
  const isMobile = useBreakpointValue({ base: true, md: false });


const appliedJobs = useAppSelector((state)=>state.appliedJobs)


console.log(appliedJobs.applicants)
console.log("array type is:",typeof(appliedJobs.applicants))

const applicationResponse =(data:{name:string,email:string,status:string})=>{
  dispatch(applicationResponseAction(data))
}


  return (
    <Box  display={{base:"block",md:'flex'}} h={{base:"98%",md:'77vh'}} >

{
  show&&
    <Card h='8vh' w='inherit' mb='10px' mt='2px' >
      <Box h='inherit' w="60%" pl='8px' display={{base:"flex",md:"none"}} justifyContent='space-between'alignItems='center'>
      <MdOutlineArrowBackIos  onClick={()=>dispatch(actions.mobileBackArrowApplied(state.mobileAppLink))}/>
      <Text>List of Applicants</Text>
      </Box>
    </Card>
  }
    {/* THIS MANAGE THE LIST OF APPLICANT FOR BOTH MOBILE AND PC */}
    
    {show&&
    <VStack flex={1}
    h={{ base:"89vh",xl:"inherit",'2xl':"90vh"}}
    
    overflowY='auto'
    css={{
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    }} 

  >
     
    
    {
      
    appliedJobs.applicants.map(item=>(
    <ListOfApplicant key={item.email} email={item.email}   name={item.name!} position= {item.jobTitle!} date="30/05/2023"/>
    
    ))
  }
  </VStack>}

  {/* THIS IS FOR MOBILE VIEW THE APPBAR */}
  {showdetails&&
    <Card h='8vh' w='inherit' mb='10px' mt='2px' >
      <Box h='inherit' w="60%" pl='8px' display={{base:"flex",md:"none"}} justifyContent='space-between'alignItems='center'>
      <MdOutlineArrowBackIos  onClick={()=>dispatch(actions.mobileBackArrowApplied(state.mobileAppLink))}/>
      <Text>Applied Jobs</Text>
      </Box>
    </Card>
  }

  {/* THIS PART MANAGESE THE DISPLAY CONTENT VIEW OF ALL THE APPLICAANT FOR BOTH MOBILE AND PC */}
  


  {
  showdetails&&
  <Box flex={3} display={{base:"block",md:"block"}} h={{base:"90vh",md:"inherit"}} >
  <Card h={{base:"90vh",xl:"inherit",'2xl':"85vh"}} p={5} 
   overflowY='auto'
   css={{
     '&::-webkit-scrollbar': {
       width: '0px',
     },
   }} 
  >
    {
      appliedJobs.applicants.map(item=>(
        (item.email===appliedJobs.active.email&&item.name===appliedJobs.active.name)&&<Box key={item.email} >
        <Heading  fontSize='18'> An application for the post of a {item.jobTitle} </Heading>
        <Text variant='p' pb="2" pt='2'>Personal:</Text>
        <ApplicationText name=' Name:'   value= {item.name!} />
        <ApplicationText name='Email:'   value={item.email!} />
        <ApplicationText name='Address' value={item.address!} />
        <ApplicationText name='Tel:' value= {item.phone!} />
        <ApplicationText name='Town:' value= {item.town!} />
        <ApplicationText name='Website:' value= {item.website!} />
        <Text variant='p' pb="2" pt='2'>Education:</Text>
        <ApplicationText name='School:' value= {item.school!} />
        <ApplicationText name='Degree:'  value={item.degree!} />
        <ApplicationText  name='DegreeStatus:' value= {""}/>
        <ApplicationText name='Country:' value= {item.country!} />
        {/* <Text>Address: {item} </Text> */}
        <Text variant='p' pb="2" pt='2'>Experience:</Text>

        <ApplicationText name='Coverlatter:' value= {""}/>

        <object data={ApiConfig.appliedFiles+item.resume} width="97%"  height={isMobile?"50%":'97%'}  type='application/pdf'/>
        
          
        <Flex mt="2vh" mb='1rem' justifyContent='space-around' w='100%'>
            <Button onClick={()=>applicationResponse({name:item.name!,email:item.email!,status:"No"})}>Reject</Button>
            <Button onClick={()=>applicationResponse({name:item.name!,email:item.email!,status:"Yes"})}>Accept</Button>
        </Flex> 
      
        <Box  mt={{base:38,md:5}} h={{base:18,md:5}}/>
    </Box>
     )
        )
    }
    {
      appliedJobs.active==null&&
      <Box display='flex' justifyContent='center' alignItems='center' h='inherit'>
          <Heading fontSize={16} >EMPTY PLEASE CLICK ON ONE</Heading>
      </Box>
    }
  </Card>
  </Box>}
</Box>
  )
}











 





