import ApiConfig from '../../api'
import httpservices from '../../httpServices'
import { Interns } from '../model/intershipInterface'

  export const getInterns =async()=>{
     return await httpservices.get(ApiConfig.interns)
  //   return  [
  //     {
  //         name:"John William",
  //         email:"william@gmail.com",
  //         town:"Buea",
  //         address:"Molyko",
  //         phone:"67334455",
  //         school:"HIMS",
  //         duration:"2month",
  //         authorization:"auth.pdf",
  //         startDate:"06/07/2023"
  //     }    
  // ]
 }