import { Card,Box, Heading, HStack, Input,Text, Flex, Button, Icon, useColorMode,} from '@chakra-ui/react'
import {MoonIcon, Search2Icon} from '@chakra-ui/icons'
import {  MdOutlineLightMode, MdOutlineModeNight, MdOutlineNotifications, MdOutlinePerson, MdOutlineSettings } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '../features/hooks/hooks';
import { getVolunteerAction } from '../features/volunteer/VolunteerSlice';
import { getAppliedJobsAction } from '../features/appliedJobs/appliedSlice';
import { DashboardInfoAction } from '../features/dashboardInfo/DashboardInfoSlice';
import { useEffect } from 'react';
const TopMainContent = () => {
//   const dashboarInfo = useAppSelector(state=>state.dashboardInfo)
//   const volunteer = useAppSelector(state=>state.volunteer)
//   const appliedJobs = useAppSelector((state)=>state.appliedJobs)

  
//   const dispatch = useAppDispatch();


//   useEffect( ()=>{
//  dispatch(getVolunteerAction())
//  dispatch(DashboardInfoAction.numberVolunter(volunteer.volunteers.length))

// //  dispatch(getAppliedJobsAction())
// //  dispatch(DashboardInfoAction.numberAppliedJobs(appliedJobs.applicants.length))

//  },)
    // const {collapseSidebar} = useProSidebar()
    const { colorMode, toggleColorMode } = useColorMode()
  return (

 /*
 *
  *
 THIS IS THE HEADER OF THE DASHBOARD
 THIS HEADER WILL APPEAR AT THE MIDDLE TO THE DOCUMENT

  *
*/

 <Box display={{md:"block", base:"none"}}>
   <HStack  justifyContent='space-between' mt={3}>
    <Box  >
    <Heading variant='h4'>Dashboard</Heading>
    
    </Box>
    <Flex pr={3} >
      <MdOutlinePerson/>
      <Box pr={2}/>
      <MdOutlineNotifications/>
      <Box pr={2}/>
      <MdOutlineSettings  />
      <Box pr={2}/>
      
      {colorMode=== 'light'?
      <MdOutlineModeNight onClick={toggleColorMode} >{colorMode === 'light' ? 'Dark' : 'Light'}</MdOutlineModeNight>:
      <MdOutlineLightMode onClick={toggleColorMode}>{colorMode === 'dark' ? 'Light' : 'Dark'}</MdOutlineLightMode>}
    </Flex>
   </HStack>
 </Box>
    
  )
}

export default TopMainContent
