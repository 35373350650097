
import { createSlice } from "@reduxjs/toolkit";

import { NavState } from "./model/navinterface";


 const initialState:NavState={

    activeLink:"Dashboard",
    activeApp:0,
    activeInt:0,
    activeVol:0,
    activeNoti:0,
    
    initial:true,
    mobileAppLink:false,
    mobileVolLink:true,
    mobileIntLink:false,
    notification:false

}


export const navLinkSlice = createSlice({
    name:'activLinks',
    initialState,
    reducers:{
        changeLinkState(state, action){
            state.activeLink=action.payload
            state.initial=false
        },
        changeAppliedJob(state,action){
            state.activeApp= action.payload
            
        },
        changeVolunteer(state,action){
            state.activeVol= action.payload
        },
        changeIntern(state,action){
            state.activeInt= action.payload
            
        },
        changeNotification(state,action){
            state.activeNoti= action.payload
          
        },

        mobileBackArrowApplied(state,action){
            state.mobileAppLink=!state.mobileAppLink
        },
        mobileBackArrowVolunteer(state,action){
            state.mobileVolLink=!action.payload
        },
        mobileBackArrowInternship(state,action){
            state.mobileIntLink=!state.mobileIntLink 
        },
        
        Notification(state,action){
            state.notification=!state.notification
        }

    }
})


export const  actions = navLinkSlice.actions
export default navLinkSlice.reducer