import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApplicationResponse } from "./responseModel";
import { applicationResponse } from "./applicationService";


const initialState:ApplicationResponse={
    name: "",
    email: "",
    status: ""
}
export const applicationResponseAction=createAsyncThunk(
    "response",
    async(data:ApplicationResponse)=>{
        const response = await applicationResponse(data)
        return response.data
    }
)
const applicationSlice = createSlice({
    name:"applicationresponse",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(applicationResponseAction.fulfilled,(state,action)=>{
            
        })
    },
    
})