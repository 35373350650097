
import {Box} from '@chakra-ui/react'
import Sidebar from './common/Sidebar';

import { Route, Routes } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import PostJob from './components/PostJobs/PostJob';
import { Applied_Jobs } from './components/applied jobs/Applied_Jobs';
import { Volunteering } from './components/volunteering/Volunteering';
import { Internship } from './components/internship/Internship';
import Graph from './components/graph/Graph';
import Login from './components/login/Login';
import Register from './components/register/register';




function App() {
  return (
    <>
      <Routes>
      <Route path='/' element={<Login/>}/>
      <Route path='/register' element={<Register/>}/>
      <Route path='/dashboard' element={<Dashboard/>}>
      
          <Route path='/dashboard'   element={<Graph/>}/>
          <Route path='/dashboard/postjob'     element={<PostJob/>}/>
          <Route path='/dashboard/appliedjobs' element={<Applied_Jobs/>}/>
          <Route path='/dashboard/volunteer' element={<Volunteering/>}/>
          <Route path='/dashboard/internship'  element={<Internship/>}/> 
      </Route>
       
      </Routes>
    </>
  );
}

export default App;



export const DashboardRoutes = () => {
  return (
    <Box display={{md:'flex',sm:"block"}} pos={{base:'sticky',md:"relative"}} >
      
      <Sidebar />

    <Routes>

        
        <Route path='/dashboard' element={<Dashboard/>}>
          <Route path='/dashboard'   element={<Graph/>}/>
          <Route path='/dashboard/postjob'     element={<PostJob/>}/>
          <Route path='/dashboard/appliedjobs' element={<Applied_Jobs/>}/>
          <Route path='/dashboard/volunteer' element={<Volunteering/>}/>
          <Route path='/dashboard/internship'  element={<Internship/>}/> 
        </Route>
    </Routes>     
  </Box>
  )
}


