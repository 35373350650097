import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Internship } from "./model/postInternship";
import { postInternship } from "./services/internService";


const initialState:Internship={
    jobTitle: null,
    location: null,
    company: null,
    description: null,
    env: null,
    deadLine: null,
    duration: null,
    minQualification: [],
    responsibilities: [],
    skills: []
}

export const postInternshipAction = createAsyncThunk(
    "internship",
    async(data:Internship)=>{
        console.log("internship data is being post",data)
        const  response  =  await postInternship(data)
        console.log(response.data)
        return response.data
    }
)

const internshipSlice =createSlice({
    name:"internshipPost",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(postInternshipAction.fulfilled,(state,action)=>{
                console.log(action.payload)
        })
    },
})

export const internshipAction = internshipSlice.actions

export default internshipSlice.reducer
