import httpServices from '../../httpServices'
import { applicantDetails } from '../model/applyModel'

import ApiConfig from '../../api'


export const getAppliedJobs = async() =>{
  return await  httpServices.get<applicantDetails[]>(ApiConfig.appliedJobs) 
//   return await [
//     {
//         id:1,
//         name:"John Doe",
//         email:"johndoe@gmail.com",
//         phone:"672203994",
//         address:"molyko",
//         town:"Buea",
//         jobTitle:"Flutter",
//         additionalEmail:"doe@gmail.com",
//         website:"deo.com",
//         school:"LYCEE",
//         degree:"BTECH",
//         degreeStatus:"completed",
//         major:"Software",
//         country:"Cameroon",
//         city:"Buea",
//         employeer:"Lexi",
//         endDate:"...",
//         startDate:"October 2022",
//         workCountry:"Cameroon",
//         workTitle:"React Developer",
//     }    
// ]
}
 
