import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { postJobs} from "./services/postServices"
import { Post, PostInterface } from "./model/postInterface"


const initialState:PostInterface={
    post: null,
    isLoading: null,
    isSuccess: null,
    isError: null,
    response: null
}

export const postJobsAction = createAsyncThunk(
    "postJobs",
    async(data:Post)=>{

        console.log(data)
        console.log("THIS IS THE POSTED DATA")
        const response = await postJobs(data)
        console.log(response.data)
        return response.data
    }
)

const postSlice = createSlice({
    name:"postJobs",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(postJobsAction.fulfilled,(state,action)=>{
            state.response = action.payload
            console.log(action.payload)
        })
       
    },
})

export  const postAction = postSlice.actions
export default postSlice.reducer