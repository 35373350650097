import { configureStore,createSlice,PayloadAction } from "@reduxjs/toolkit";
import InternReducer from '../../features/internship/InternshipSlice'

import { NavState } from "../../features/navigation/model/navinterface";
import  appliedReducer   from "../../features/appliedJobs/appliedSlice";
import voluteerReducer   from '../../features/volunteer/VolunteerSlice'
import navReducer        from '../../features/navigation/navSlice'
import postReducer       from '../../features/postJobs/postSlice'
import internshipReducer from '../../features/postInternship/internshipSlice'
import volunteerReducer  from '../../features/postVolunteer/postVolunteerSlice'
import dashboarInfoReducer from  '../../features/dashboardInfo/DashboardInfoSlice'


export const store = configureStore({
    reducer:{
        nav           : navReducer,
        internships   : InternReducer,
        appliedJobs   : appliedReducer,
        volunteer     : voluteerReducer,
        postInternship: internshipReducer,
        postjobs      : postReducer,
        postVolunteer : volunteerReducer,
        dashboardInfo : dashboarInfoReducer
         }
})



export type  RootState =ReturnType<typeof store.getState>;
export type AppDispatch  = typeof store.dispatch


