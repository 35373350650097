import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  VolunteerInteface } from "./model/volunteerInterface";
import { getVolunteer } from "./services/volunteerServices";



const initialState:VolunteerInteface={
    // volunteers: FakeVolunteer,
    volunteers: [],
    active: {name:'',email:""}
}
export const  getVolunteerAction = createAsyncThunk(

    "volunteer",
    async()=>{
        const response= await getVolunteer();
        
        console.log("VOLUNTEERS FROM THE BACKEND",response.data);
        console.log("VOLUNTEERS FROM THE BACKEND",response.data);

    
        return response.data;
    }

)

const volunteerSlice = createSlice({
    name:"volunteer",
    initialState,
    reducers:{
        changeActiveVolunteer(state,action){
                state.active=action.payload
                
        }
    },
    extraReducers(builder) {

        builder.addCase(getVolunteerAction.fulfilled,(state,action)=>{
            if(action.payload.length!==2)
              state.volunteers = action.payload
        })
        
    },
})

export  const  voluteerAction = volunteerSlice.actions
export default volunteerSlice.reducer