const baseURI ="http://api.tekstedia.com/API"
const FILEBASEURI ="http://api.tekstedia.com/Core/Files/postFiles/"
const INTERNSHIPFILEBASEURI ="http://api.tekstedia.com/Core/Files/internship/"

const  ApiConfig={
   appliedJobs              : `${baseURI}/appliedJob.php`,
   interns                  : `${baseURI}/interns.php`,
   volunteer                : `${baseURI}/appliedVolunteers.php`,
   postJobs                 : `${baseURI}/createJobs.php`,
   postInternship           : `${baseURI}/createInternship.php`,
   postVolunteer            : `${baseURI}/createVolunteer.php`,
   notification             : `${baseURI}/notification.php`,
   getpostedInternship      : `${baseURI}/postInternship.php`,
   getpostedJobs            : `${baseURI}/postJobs.php`,
   getpostedVolunteerism    : `${baseURI}/postVolunteer.php`,
   applicationResponse      : `${baseURI}/jobApplicationStatus.php`,
   appliedFiles             : `${FILEBASEURI}/`,
   internshipFiles          : `${INTERNSHIPFILEBASEURI}/`,
   
}

export default ApiConfig