import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { postVolunteer } from "./services/postServices"
import { Volunteer, PostInterface } from "./model/postInterface"


const initialState:PostInterface={
    post: null,
    isLoading: null,
    isSuccess: null,
    isError: null,
    response: null
}

export const volunteerAction = createAsyncThunk(
    "postVolunteer",
    async(data:Volunteer)=>{

        console.log(data)
        console.log("THIS IS THE POSTED VOLUNTEER DATA")
        const response = await postVolunteer(data)
        console.log(response.data)
        return response.data
    }
)

const postVolunteerSlice = createSlice({
    name:"postJobs",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(volunteerAction.fulfilled,(state,action)=>{
            state.response = action.payload
            console.log(action.payload)
        })
       
    },
})

export  const  postVolunteerAction = postVolunteerSlice.actions
export default postVolunteerSlice.reducer