import React, { RefObject, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Input,
  Select,
  Text,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
  Textarea,
} from '@chakra-ui/react';

import { AppDrawer } from '../../common/drawer'; 

import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { useAppDispatch } from '../../features/hooks/hooks';
import { postJobsAction } from '../../features/postJobs/postSlice';
import { Post } from '../../features/postJobs/model/postInterface';
import { volunteerAction } from '../../features/postVolunteer/postVolunteerSlice';
import { postInternshipAction } from '../../features/postInternship/internshipSlice';
import { Internship } from '../../features/postInternship/model/postInternship';
import { DashboardInfoAction } from '../../features/dashboardInfo/DashboardInfoSlice';

const MAX_DISPLAY_LENGTH = 20;

const PostJob = () => {
  // State variables
  const { isOpen, onOpen, onClose } = useDisclosure(); // Manages the open and close state of an alert dialog
  const cancelRef = React.useRef<HTMLButtonElement>(null); // Reference to a cancel button
  
  const [type, setType]                                      = useState("");
  const [jobTitle,setJobTitle]                               = useState("")
  const [location,setLocation]                               = useState("")
  const [company,setCompany]                                 = useState("")
  const [env,setEnv]                                         = useState("")
  const [description,setDescription]                         = useState("")
  const [note,setNote]                                       = useState("")
  const [jobType,setJobType]                                 = useState("")
  const [deadLine,setDeadline]                               = useState(new Date())
  const [minQualificationText,setMinQualificationText]       = useState("")
  const [prefQualificationText,setPrefMinQualificationText]  = useState("")
  const [requirementText,setRequirementText]                 = useState("")
  const [aboutJobText,setAboutJobText]                       = useState("")
  const [minQualification,setMinQualification]               = useState<string[]>([])
  const [prefQualification,setPrefMinQualification]          = useState<string[]>([])
  const [responsibilities,setReponsibilities]                = useState<string[]>([])
  const [aboutTheJob,setAboutTheJob]                         = useState<string[]>([])
  const [skills,setSkills]                                   = useState<string[]>([])
  
  // INTERSHIP  
  const [internshipType,setinternshipType]                   = useState("")
  const [duration,setDuration]                               = useState("")

  const dispatch = useAppDispatch()


  const handleMinQualification =(event?:React.KeyboardEvent<HTMLTextAreaElement>)=>{

    if(event!.key==="Enter"){ 
    setMinQualification([...minQualification,minQualificationText])
   
    setMinQualificationText("")
    }

  }
  const handlePreQualification =(event?:React.KeyboardEvent<HTMLTextAreaElement>)=>{
    if(event!.key==="Enter"){ 
    setPrefMinQualification([...prefQualification,prefQualificationText])
      setPrefMinQualificationText("")
    
    }

  }
  const handleResponsibilities =(event?:React.KeyboardEvent<HTMLTextAreaElement>)=>{
    if(event!.key==="Enter"){ 
    setReponsibilities([...responsibilities,requirementText])
    setRequirementText("")
    
    }

  }
  const handleAboutJob =(event?:React.KeyboardEvent<HTMLTextAreaElement>)=>{
    if(event!.key==="Enter"){ 
    setAboutTheJob([...aboutTheJob,requirementText])
    setRequirementText("")
    
    }

  }
  const handleSkills =(event?:React.KeyboardEvent<HTMLTextAreaElement>)=>{
    if(event!.key==="Enter"){ 
    setSkills([...skills,requirementText])
    setRequirementText("")
    
    }

  }

  

  /*
  const [minimumQualifications, setMinimumQualifications] = useState<
    string[]
  >([]); // Stores the minimum qualifications as an array of strings
  const [preferredQualifications, setPreferredQualifications] =
    useState<string[]>([]); // Stores the preferred qualifications as an array of strings
  const [minimumQualificationInput, setMinimumQualificationInput] =
    useState(''); // Stores the input value for a new minimum qualification
  const [
    preferredQualificationInput,
    setPreferredQualificationInput,
  ] = useState(''); // Stores the input value for a new preferred qualification
  const [
    selectedMinimumQualificationIndex,
    setSelectedMinimumQualificationIndex,
  ] = useState<number>(-1); // Stores the index of the selected minimum qualification
  const [
    selectedPreferredQualificationIndex,
    setSelectedPreferredQualificationIndex,
  ] = useState<number>(-1); // Stores the index of the selected preferred qualification

  // Event handlers for input changes
  const handleMinimumQualificationInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setMinimumQualificationInput(event.target.value);
  };

  const handlePreferredQualificationInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPreferredQualificationInput(event.target.value);
  };*/

  // Event handlers for adding qualifications
  // const handleMinimumQualificationSubmit = (
    // event: React.KeyboardEvent<HTMLInputElement>,
  // ) => {
    // if (event.key === 'Enter' && minimumQualificationInput) {
    //   setMinimumQualifications((prevQualifications) => [
    //     ...prevQualifications,
    //     minimumQualificationInput,
    //   ]);
    //   setMinimumQualificationInput('');
    // }

  // };
/*
  const handlePreferredQualificationSubmit = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter' && preferredQualificationInput) {
      setPreferredQualifications((prevQualifications) => [
        ...prevQualifications,
        preferredQualificationInput,
      ]);
      setPreferredQualificationInput('');
    }
  };

  // Event handlers for selecting qualifications
  const handleMinimumQualificationClick = (index: number) => {
    setSelectedMinimumQualificationIndex(index);
  };

  const handlePreferredQualificationClick = (index: number) => {
    setSelectedPreferredQualificationIndex(index);
  };

  // Event handlers for editing qualifications
  const handleMinimumQualificationEdit = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedQualifications = [...minimumQualifications];
    updatedQualifications[selectedMinimumQualificationIndex] =
      event.target.value;
    setMinimumQualifications(updatedQualifications);
  };

  const handlePreferredQualificationEdit = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedQualifications = [...preferredQualifications];
    updatedQualifications[selectedPreferredQualificationIndex] =
      event.target.value;
    setPreferredQualifications(updatedQualifications);
  };

  // Event handlers for deleting qualifications
  const handleDeleteMinimumQualification = (index: number) => {
    const updatedQualifications = [...minimumQualifications];
    updatedQualifications.splice(index, 1);
    setMinimumQualifications(updatedQualifications);
  };

  const handleDeletePreferredQualification = (index: number) => {
    const updatedQualifications = [...preferredQualifications];
    updatedQualifications.splice(index, 1);
    setPreferredQualifications(updatedQualifications);
  };
*/


const handleSendFormData =()=>{
  

  const data:Post={
    jobTitle,
    location,
    company,
    env,
    note,
    jobType,
    deadLine,
    minQualification,
    prefQualification,
    responsibilities,
    aboutTheJob
  };
  type==="Job"?
  dispatch(postJobsAction(data)):
  dispatch(volunteerAction(data))
  
  
  dispatch(DashboardInfoAction.incrementPostJobs())
  clearText()
}

const handleInternshipForm =()=>{
  const data:Internship={
    jobTitle,
    location,
    company,
    description,
    env,
    deadLine,
    duration,
    minQualification,
    responsibilities,
    skills
  };
  dispatch(postInternshipAction(data))

  clearText()


}
 const clearText=()=>{
  setJobTitle("")
  setAboutJobText("")
  setCompany("")
  setDescription("")
  setSkills([])
  setEnv("")
  setJobType("")
  setSkills([])
  setNote("")
  setReponsibilities([])
  
}

  return (
    <Box     h={{base: '90%',sm:'80%',md:"40%",xl:'100%','2xl':'86%'}}>
      <AppDrawer text="Post Job" />{' '}
      {/* Renders the AppDrawer component with "Post Job" text */}
      <Card
        mt={{ base: '12px', md: 0 }}
        h={{base: '90%',sm:'80%',md:"40%",xl:'80%','2xl':'100%'}}
        w={{ base: '94vw', md: 'initial' }}
        // backgroundColor={{base:"red",md:"blue",lg:"pink",xl:"blue",'2xl':"yellow"}}
        display={{ base: 'flex' }}
        justifyContent={{ base: 'center', md: 'initial' }}
        alignItems={{ base: 'center', md: 'initial' }}
      >
        <Box
          h="initial"
          w="inherit"
          mb={3}
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
          }}
        >
           <Box
              flex={1}
              display="flex"
              p={4}
              // justifyContent="space-between"
            >
              <Text
                flex={1}
                pr={4}
              >
                Type:
              </Text>
              <Box w={{base:"10rem",md:"15rem"}}>
              <Select  onChange={(e)=>setType(e.target.value)}>
                <option value="">None</option>
                <option value="Job">Job</option>
                <option value="Volunteer">Volunteer</option>
                <option value="Internship">Internship</option>
              </Select>
              </Box>
            </Box>
            
          {/* Job  and Volunteering Form */}

         
          <Box>
          <Box
            width="inherit"
            display="flex"
            alignItems="center"
          >
            <Box flex={1}>
              <CustomInput text="Job Title" handleInputFunction={(e)=>setJobTitle(e.target.value)} />
            </Box>
            <Box flex={1}>
              <CustomInput text="Location" handleInputFunction={(e)=>setLocation(e.target.value)} />
            </Box>
          </Box>

          <Box
            width="inherit"
            display="flex"
            alignItems="center"
          >
            <Box flex={1}>
              <CustomInput text="Company" handleInputFunction={(e)=>setCompany(e.target.value)} />
            </Box>
            <Box flex={1}>
              
             {
              type!=="Internship"? 
              <CustomInput text="Note" handleInputFunction={(e)=>setNote(e.target.value)} />:
              <CustomInput text="Description" handleInputFunction={(e)=>setDescription(e.target.value)} />
             }
            </Box>
          </Box>
          <Box
            width="inherit"
            display="flex"
            alignItems="center"
          >
            <Box flex={1}>
            <Box
              flex={1}
              display="flex"
              p={4}
              justifyContent="space-between"
            >
              <Text
                flex={1}
                pr={4}
              >
                Job type:
              </Text>
              <Select flex={1} onChange={(e)=>setJobType(e.target.value)}>
                <option value="None">None</option>
                <option value="Full time">Full time</option>
                <option value="Part time">Part time</option>
              </Select>
            </Box>
            </Box>
            <Box flex={1}>
              
             {
              type==="Internship"&&
              <CustomInput text="Duration" handleInputFunction={(e)=>setDuration(e.target.value)} />
            
             }
            </Box>
          </Box>
          {/* Qualifications input */}
          {/* <Flex
            ml="10rem"
            gap="1rem"
            maxW="70vw"
            overflow='auto'
            css={{
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            }} 
          >
            {minimumQualifications.map((qualification, index) => (
              <Box
                key={qualification}
                bg={
                  selectedMinimumQualificationIndex === index
                    ? 'white'
                    : 'gray.100'
                }
                mt={2}
                p={2}
                borderRadius="md"
                onClick={() => handleMinimumQualificationClick(index)}
                cursor="pointer"
              >
                {selectedMinimumQualificationIndex === index ? (
                  <Flex align="center">
                    <Textarea
                      value={qualification}
                      // onChange={handleMinimumQualificationEdit}
                    />
                    <IconButton
                      aria-label="Delete qualification"
                      icon={<FaTrash color="red" />}
                      onClick={() =>
                        handleDeleteMinimumQualification(index)
                      }
                      ml={2}
                      variant="ghost"
                    />
                  </Flex>
                ) : (
                  <Flex align="center">
                    <Text
                      fontSize="sm"
                      flex={1}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {qualification}
                    </Text>
                    <IconButton
                      aria-label="Delete qualification"
                      icon={<FaTrash color="red" />}
                      onClick={() =>
                        handleDeleteMinimumQualification(index)
                      }
                      variant="ghost"
                    />
                  </Flex>
                )}
              </Box>
            ))}
          </Flex> */}
          {/* randomlySelectAll(imageName).length */}

          {/* <Flex
            ml="10rem"
            gap="1rem"
            maxW="70vw"
            borderRadius="md"
            overflow='auto'
            css={{
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            }} 
          >
            {preferredQualifications.map((qualification, index) => (
              <Box
                key={qualification}
                bg={
                  selectedPreferredQualificationIndex === index
                    ? 'white'
                    : 'gray.100'
                }
                mt={2}
                p={2}
                borderRadius="md"
                onClick={() =>
                  handlePreferredQualificationClick(index)
                }
                cursor="pointer"
              >
                {selectedPreferredQualificationIndex === index ? (
                  <Flex alignItems="center">
                    <Input
                      value={qualification}
                      onChange={handlePreferredQualificationEdit}
                      onBlur={() =>
                        setSelectedPreferredQualificationIndex(-1)
                      }
                    />
                    <IconButton
                      aria-label="Delete"
                      icon={<FaTrash color="red" />}
                      onClick={() =>
                        handleDeletePreferredQualification(index)
                      }
                      ml={2}
                    />
                  </Flex>
                ) : (
                  <Flex alignItems="center">
                    <Text
                      fontSize="sm"
                      mr={2}
                      // color='black'
                    >
                      {qualification.slice(0, MAX_DISPLAY_LENGTH)}
                    </Text>
                    <IconButton
                      aria-label="Delete"
                      icon={<FaTrash color="red" />}
                      onClick={() =>
                        handleDeletePreferredQualification(index)
                      }
                    />
                  </Flex>
                )}
              </Box>
            ))}
          </Flex> */}

<Flex
            display={{ base: 'block', md: 'flex' }}
            p={4}
            alignItems="center"
          >
            <Text
              flex={1}
              pr={0}
            >
              Min Qualification:
            </Text>

            <Textarea
              flex={6.3}
              mt={2}
              placeholder="Type a point and hit enter"
              value={minQualificationText}
              overflow="auto"
              css={{
                '&::-webkit-scrollbar': {
                  width: '0px',
                },
              }}
              onChange={(e)=>setMinQualificationText(e.target.value)}
              onKeyPress={(e)=>handleMinQualification(e)}
            onBlur={(e)=>setMinQualification([...minQualification,e.target.value])}
              // onBlur={(e)=>handleMinQualification()}
            />
          </Flex>
         
          {
            type!=="Internship"&&
            <Flex
            display={{ base: 'block', md: 'flex' }}
            p={4}
            alignItems="center"
          >
            <Text
              flex={1}
              pr={0}
            >
              Pref Qualification:
            </Text>
            <Textarea
             overflow="auto"
             css={{
               '&::-webkit-scrollbar': {
                 width: '0px',
               },
             }}
              mt={2}
              flex={6.3}
              placeholder="Type a point and hit enter"
              value={prefQualificationText}
              
              onChange={(e)=>setPrefMinQualificationText(e.target.value)}
              onKeyPress={(e)=>handlePreQualification(e)}
              onBlur={(e)=>setPrefMinQualification([...prefQualification,e.target.value])}
            />
          </Flex>
          }

          <Flex
            display={{ base: 'block', md: 'flex' }}
            p={4}
            alignItems="center"
          >
            <Text
              flex={1}
              pr={0}
            >
              Responsibilities:
            </Text>

            <Textarea
             overflow="auto"
             css={{
               '&::-webkit-scrollbar': {
                 width: '0px',
               },
             }}
              flex={6.3}
              mt={2}
              placeholder="Type a point and hit enter"
              value={requirementText}
              
              onChange={(e)=>setRequirementText(e.target.value)}
              onKeyPress={(e)=>handleResponsibilities(e)}
              onBlur={(e)=>setReponsibilities([...responsibilities,e.target.value])}
            />
          </Flex>
          {
            type!=="Internship"&&

            <Flex
            display={{ base: 'block', md: 'flex' }}
            p={4}
            alignItems="center"
          >
            <Text
              flex={1}
              pr={0}
            >
              About Jobs:
            </Text>

            <Textarea
             overflow="auto"
             css={{
               '&::-webkit-scrollbar': {
                 width: '0px',
               },
             }}
              flex={6.3}
              mt={2}
              placeholder="Type a point and hit enter"
              value={aboutJobText}
              
              onChange={(e)=>setAboutJobText(e.target.value)}
              onKeyPress={(e)=>handleAboutJob(e)}
              onBlur={(e)=>setAboutTheJob([...aboutTheJob,e.target.value])}
            />
          </Flex>
          }
          

          {type =="Internship"&&
          <Box>
             
            {/* <Box width='inherit' >
              <CustomInput text="Type" handleInputFunction={(e)=>setinternshipType(e.target.value)} />
            </Box> */}

          <Flex
            display={{ base: 'block', md: 'flex' }}
            p={4}
            alignItems="center"
          >
            <Text
              flex={1}
              pr={0}
            >
              Skills:
            </Text>

            <Textarea
             overflow="auto"
             css={{
               '&::-webkit-scrollbar': {
                 width: '0px',
               },
             }}
              flex={6.3}
              mt={2}
              placeholder="Type a point and hit enter"
              value={requirementText}
              onChange={(e)=>setRequirementText(e.target.value)}
              onKeyPress={(e)=>handleSkills(e)}
              onBlur={(e)=>setSkills([...skills,e.target.value])}
            />
          </Flex>
           
          </Box>

          }

          <Box display={{ base: 'block', md: 'flex' }}>
            <Box
              flex={1}
              display="flex"
              p={4}
              justifyContent="space-between"
            >
              <Text
                flex={1}
                pr={4}
              >
                Environment:
              </Text>
              <Select flex={1} onChange={(e)=>setEnv(e.target.value)}>
                <option value="None">None</option>
                <option value="On Site">On Site</option>
                <option value="On Line">On Line</option>
              </Select>
            </Box>
            <Box
              flex={1}
              display={{ base: 'block', md: 'flex' }}
              p={4}
              justifyContent="center"
              alignItems="center"
            >
              <Text
                flex={2}
                pr={4}
              >
                Dead Line:
              </Text>
                <Box flex={1}>
                <SingleDatepicker
            name="date-input"
            date={deadLine}
            onDateChange={setDeadline}
          />
                </Box>
            </Box>
          </Box>
          </Box>
          

          {/* INTERNSHIP FORM */}
        




          <Box
            display="flex"
            justifyContent="center"
            mt={{ base: 5, md: 2 }}
            mb={{ base: 20, md: 8 }}
          >
            <Button onClick={type!="Internship"?handleSendFormData:handleInternshipForm}>Submit</Button>

            {/* <AlertDialog
              leastDestructiveRef={cancelRef}
              isOpen={isOpen}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader
                    fontSize="lg"
                    fontWeight="bold"
                  ></AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You can't undo this action
                    afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                      colorScheme="red"
                      onClick={()=>handleSendFormData}
                      ml={3}
                    >
                      Done
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog> */}

          </Box>
         

          
        </Box>
      </Card>
    </Box>
  );
};

export default PostJob;

type inputProps = {
  text: string,
  handleInputFunction:(e:React.ChangeEvent<HTMLInputElement>)=>void

};

export const CustomInput = ({ text,handleInputFunction }: inputProps) => {
  return (
    <Box
      display={{ base: 'block', md: 'flex' }}
      p={4}
      justifyContent="center"
      alignItems="center"
    >
      <Text
        flex={1}
        pr={4}
      >
        {text}:
      </Text>
      <Input
        onChange={(e)=>handleInputFunction(e)}
        flex={3}
        w={{ base: '99%', md: '70%' }}
      />
    </Box>
  );
};
