import {Box,Card,Divider,Flex,Image,MenuList,Text, Heading, VStack, baseTheme, useBreakpointValue} from '@chakra-ui/react';
import person  from '../images/person.jpg'
import {MdLogout,MdWorkOutline,MdOutlineDashboard,MdOutlinePostAdd,MdOutlineVolunteerActivism, MdMenu, MdOutlineSettings, MdOutlinePerson,MdOutlineArrowBackIos,} from 'react-icons/md'
import { Link } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux'
import { NavState } from '../features/navigation/model/navinterface';
import { actions } from '../features/navigation/navSlice';


const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}
const Sidebar = () => {
    
  return (
   <Box h={{md:'100vh'}} flex={{md:1}} >

    {/* Mobile NavBar */}

    <Box pos="fixed" bottom={10}  zIndex={2} display={{md:"none", base:"flex"}}   m='10px'>
      <Box w='95vw' h='6vh'  bgColor="GrayText"  borderRadius='23px' position={{base:"fixed",md:"relative"}}>
        
        <Box display='flex' h='inherit' justifyContent='center' alignItems="center" >
          <Box w='35px'/>
          <MenuItems path=''   text='Dashboard' image={<MdOutlineDashboard/>}/>
          <MenuItems path='/postjob' text='Post' image={<MdOutlinePostAdd/>}/>
          <MenuItems path='/appliedjobs' text='Applied' image={<MdWorkOutline/>}/>
          <MenuItems path='/volunteer' text='Volunteer' image={<MdOutlineVolunteerActivism/>}/>
          <MenuItems path='/internship' text='Intern' image={<MdWorkOutline/>}/> 
        </Box>
      </Box>
    </Box>

    {/* Desktop NavBar */}
    <Card   display={{md:"flex", base:"none",}} h='100%' justifyContent='start' alignItems='center'flexDirection='column'
    // pos={{md:"",base:"absolute",bottom:12}}
    >   
    <Image src={person} borderRadius='50%'  w='50%' marginTop='2rem' mb='0.3rem' />
    <Text mb='10px'>TekStedia Lexi</Text>
    <Divider mt={2} mb={5}/>
      <MenuItems path=''   text='Dashboard' image={<MdOutlineDashboard/>}/>
      <MenuItems path='/postjob' text='Post Job' image={<MdOutlinePostAdd/>}/>
      <MenuItems path='/appliedjobs' text='Applied Jobs' image={<MdWorkOutline/>}/>
      <MenuItems path='/volunteer' text='Volunteering' image={<MdOutlineVolunteerActivism/>}/>
      <MenuItems path='/internship' text='Internship' image={<MdWorkOutline/>}/>
      <Divider mt='40%'/>
      <MenuItems path='/'  text='Log out' image={<MdLogout/>}/>
    </Card>
   </Box>
  )
}

export default Sidebar

type MenuProps={
    text:string,
    image:React.ReactNode,
    path:string
}

export const MenuItems = ({text,image,path}:MenuProps) => {

  const navlink = useSelector((state:{nav:NavState})=>state.nav.activeLink)
  const dispatch = useDispatch()

  

   const linkStyles={
    // display:"flex",  
    width:"80%", 
    
   }
  return (

     <Link to={path==="/"? "/":"/dashboard"+path} onClick={()=>dispatch(actions.changeLinkState(text))} style={ linkStyles}>
        <Box w='80%' display="flex" alignItems="center" flexDirection={{base:"column-reverse",md:"initial"}}>
            
          <Box w={{base:"4rem",md:'2px'}} h={{base:"2px",md:"14px"}} bgColor={navlink==text?'blue':""}></Box>
          <Box  display='flex' flexDirection={{base:"column",md:"initial"}} justifyContent='space-between' alignItems='center' w="100%" p='10px'>
            <Box flex={1}>{image}</Box>
            <Box flex={3} fontSize={{base:11,md:16}}>{text}</Box>
            
          </Box>
        </Box>
       
     </Link>
    
    
   
  )
  }


 
