import { Box, Card,Drawer,DrawerContent,Text, Image,useBreakpointValue, useColorMode, useDisclosure } from "@chakra-ui/react";
import React from "react"
import person  from '../images/person.jpg'

import { MdLogout, MdOutlineClose, MdOutlineEdit, MdOutlineLightMode, MdOutlineModeNight, MdOutlineMoreVert } from "react-icons/md";
import { MenuItems } from "./Sidebar";
import { Link } from "react-router-dom";

type drawerPageText ={
  text :string
}
export const  AppDrawer= ({text}:drawerPageText)=> {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // const [placement, setPlacement] = React.useState('right')
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <>
      {isMobile&&
        <Card h='10vh' w='94vw' mb='10px' mt='2px' pos='sticky' zIndex={2} top={0} left={0} right={0} >
          <Box h='inherit' w="inherit" pl='8px' pr='8px' display={{base:"flex",md:"none"}} justifyContent='space-between' alignItems='center'
          pos='fixed'
          >
          {/* <MdOutlineArrowBackIos  onClick={()=>dispatch(actions.Notification(state.notification))}/> */}
          <MdOutlineMoreVert size=' 30px' onClick={onOpen}/>
          <Text>{text}</Text>
          <Text/>

          {/*   THIS IS FOR MOBILE DRAWER ITEM */}

          <Drawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full"
                >

                    <DrawerContent>
                         <Box display='flex' justifyContent='end' p=' 18px'> <MdOutlineClose size=' 30px' onClick={onClose}/></Box>
                        <Box display='flex' justifyContent='center' alignItems='center' position='relative'>
                           <Image src={person}  borderRadius='50%'  w='30%' marginTop='2rem' mb='0.3rem' />

                         <Card 
                         borderRadius='25px' 
                         position='absolute'
                         top="45%" right="35%"
                         h='25%' w="10%"
                         display='flex' 
                         justifyContent='center' 
                         alignItems='center'
                         onClick={()=>console.log("edit profile clicked")}
                           ><MdOutlineEdit size='20px' />
                         </Card>
                        </Box>
                      <Box  ml='20px' mt='20px'>
                      <Text pt='20px'>Name: Texstedia Lexi</Text>
                      <Text pt='20px'>Email: tekstedia@gmail.com</Text>
                      <Text pt='20px'>position: Admin</Text>
                      <Text display='flex' alignItems='center' mt=' 20px'>
                        <Text pr='20px'>Click to set mode:</Text> 

                       {colorMode=== 'light'?
              <MdOutlineModeNight size='20px' onClick={toggleColorMode} >{colorMode === 'light' ? 'Dark' : 'Light'}</MdOutlineModeNight>:
              <MdOutlineLightMode size='20px' onClick={toggleColorMode}>{colorMode === 'dark' ? 'Light' : 'Dark'}</MdOutlineLightMode>}
                              
                        </Text>
                      <Link to='/'>
                      <Box display='flex' justifyContent='space-between' w='47vw' mt='10px'>
                        <Text>Logout</Text>
                        <MdLogout/>
                      </Box>
                      </Link>
                      </Box>
                    
                    </DrawerContent>
                </Drawer> 

                {/*THIS IS END OF THE DRAWER  */}
           
          </Box>
        </Card>
        }
    </>
  )
}