import { Box,Text ,Button, Card, FormControl, FormErrorMessage, FormLabel, Input, VStack, Heading, Flex } from '@chakra-ui/react'
// import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
// import * as yup from 'yup'


const Login = () => {
//    const formik = useFormik({
//     initialValues:{
       
//         email:"",
//         password:""
//     },
//     validationSchema: yup.object({
//         // name :yup.string().required("user name required"),
//         email:yup.string().required("email required").email("email is invalid"),
//         password: yup.string().required("password is required").min(6,"password length is to short")

//     })
//     ,
//     onSubmit:(value,action)=>{

//     }
//    })
  return (
   <Box  display='flex' justifyContent='center' alignItems='center' h='100vh' w='100vw'  overflow='hidden'>
        
       
        <Card h={{base:"70vh",md:'70vh'}} 
             w={{base:"93vw",md:'40vw'}}
            
             shadow='2xl'
             justifyContent='center'
             alignItems='center'
             display='flex'
             >
                
            <VStack 
        //     onSubmit={formik.handleChange}
               >
                        
                        <Heading>LOGIN FORM</Heading>

                        {/* <FormControl isInvalid={formik.errors.name?true:false}>
                                <FormLabel mb='9px' htmlFor='name'>Name</FormLabel>
                                <Input name='name'value={formik.values.name} w='60vh' h='7vh' onChange={formik.handleChange} placeholder="please enter your name"/>
                                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                        </FormControl> */}
,
                        <FormControl 
                        // isInvalid={formik.errors.email?true:false}
                        // w={{base:"100%",md:"initial"}}
                        >
                                <FormLabel mb='9px' htmlFor='email'>Email</FormLabel>
                                <Input name='email'
                                // value={formik.values.email} w='60vh'
                                
                                h='7vh' 
                                // onChange={formik.handleChange}
                                 placeholder="please enter your name"/>
                                <FormErrorMessage>
                                        {/* {formik.errors.email} */}
                                </FormErrorMessage>
                        </FormControl>
                        <FormControl 
                        // isInvalid={formik.errors.email?true:false}
                        // w={{base:"100%",md:"80vw"}}
                        >
                                <FormLabel mb='9px' htmlFor='email'>Password</FormLabel>
                                <Input name='email'
                                // value={formik.values.email} w='60vh'
                                
                                h='7vh' 
                                // onChange={formik.handleChange}
                                 placeholder="please enter your name"/>
                                <FormErrorMessage>
                                        {/* {formik.errors.email} */}
                                </FormErrorMessage>
                        </FormControl>

                   
                        
                        <Box>
                                <Button type='submit' variant="outline" h='2.4rem' w='6rem' mt={15}><Link to="/dashboard" className='link'>Submit</Link></Button> 
                        </Box>
                        <Flex>
                                <Text>If you don't have an account </Text> <Text color='blue' pl='5px'><Link to='/register' className='link'>Sign up</Link></Text>
                        </Flex>

                </VStack>
        </Card>
   </Box>
  )
}

export default Login
