import { AppliedJobs,  applicantDetails } from "./model/applyModel"
import { getAppliedJobs } from "./services/appliedServices"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


 

//  export type appliedJobsInitialValue={
//     applicants:[]
//     isLoading:boolean
//     isError:boolean 
//     isSuccess:boolean,
//     active:string|null

// }

 const initialState:AppliedJobs={
     //  applicants: FakeAppliedJobs,
     applicants: [],
     
     
     
     active: {name:'',email:''},
     selectItem: ""
 }

 export const getAppliedJobsAction = createAsyncThunk(
    
    "api/applie.php",
    async()=>{
       const response = await getAppliedJobs()
       console.log(response.data,"data")
       return response.data
    }
 )

//  const postJobsAction = createAsyncThunk(
//     "createjobs",
//     async()
//  )

 const appliedJobSlice = createSlice({
    name:"appliedJobs",
    initialState,
    reducers:{
        changeEmail(state,action){
            state.active= action.payload
        }
    },
    extraReducers:(builder)=>{

        // builder
        // .addCase(getAppliedJobsAction.pending,(state)=>{

        //     console.log("state is pending")
           

        // })

        builder.addCase(getAppliedJobsAction.fulfilled,(state,action)=>{

            state.applicants = action.payload
            console.log("the state is:",state.applicants);
           

            
        })

    }
});

export const appliedAction = appliedJobSlice.actions
export default  appliedJobSlice.reducer