import { Box, Card, Flex,Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, LinearScale,} from 'chart.js'
// import { Bar, BarChart, CartesianGrid, Pie, PieChart, XAxis, YAxis } from 'recharts';
import { AppDrawer } from '../../common/drawer';
import { useAppSelector } from '../../features/hooks/hooks';
import { Bar,Pie } from 'react-chartjs-2';

const Graph = () => {

  const data = [
    { name: 'December', students: 130 },
    { name: 'November', students: 290 },
    { name: 'October', students: 400 },
    { name: 'September', students: 400 },
    { name: 'August', students: 448 },
    { name: 'July', students: 220 },
    { name: 'June', students: 140 },
    { name: 'May', students: 200 },
    { name: 'April', students: 400 },
    { name: 'March', students: 700 },
    { name: 'Febuary', students: 200 },
    { name: 'January', students: 1000 }
];

const data01 = [
    {
      "name": "internship",
      "value": 400
    },
    {
      "name": "Jobs",
      "value": 300
    },
    {
      "name": "Volunteer",
      "value": 300
    },
    
    
  ];
    ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
)        
  const dashboarInfo = useAppSelector(state=>state.dashboardInfo)

  const [chartData,setChartData] =useState({
    labels :data.map((element)=>element.name),
    datasets:[{
      lebel:"Students",
      data:data.map((element)=>element.students),
      backgroundColor:["blue","orange","pink","purple"]
    }]
  })
  const [pieChartData,setPieChartData] =useState({
    labels :data01.map((element)=>element.name),
    datasets:[{
      lebel:"Students",
      data:data01.map((element)=>element.value),
      backgroundColor:["blue","orange","pink"]
    }]
  })
  return (

   

    <Box w="100%" display='flex' flexDirection='column' >
         <AppDrawer text="Dashboard" />
          <Flex  flexDirection={{base:"column",md:"initial"}} >
              <Box flex={3} h={{'md':"60vh"}} w={{base:"93vw",md:"initial"}} mt={{base:"10px",md:0}}>
                  <Card h="inherit" p="10px" w={{base:"inherit",md:"inherit"}} 
                  
                  overflow={{base:"scroll"}}
                  
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '0px',
                    },
                  }}
                  >
                      <Text>Application rate</Text>
                      <Box h=' 80%' display='flex' justifyContent='center' mt='20px'>
                        <Bar data={chartData}/>
                      </Box>

                      {/* <BarChart width={900} height={360} data={data}>
                          <Bar dataKey="students" fill="green" />
                          <CartesianGrid stroke="#ccc" />
                          <XAxis dataKey="name" />
                          <YAxis />
                      </BarChart> */}
                      
                  </Card>
              </Box>
              <Box flex={1}  h={{'md':"60vh"}}  w={{base:"93vw",md:"initial"}} ml="3px" mt={{base:"10px",md:0}}>
                  <Card h='inherit' p={5} >
                        <Text fontSize={15}>Application percentage</Text>
                 <Box display='flex' justifyContent='center' alignItems='center' h={{base:"80%",xl:"90%","2xl":"90%"}}>
                      
                        <Pie data={pieChartData}/>  
                 </Box>
                <Flex justifyContent='space-evenly'>
                    <Flex alignItems='center'>
                        <Box h='10px' w="10px" bgColor='blue' mr='10px'></Box>
                        <Text>Internship</Text>
                    </Flex>
                    <Flex alignItems='center'>
                        <Box h='10px' w="10px" bgColor='orange ' mr='10px'></Box>
                        <Text>Volunteerism</Text>
                    </Flex>
                    <Flex alignItems='center'>
                        <Box h='10px' w="10px" bgColor='pink' mr='10px'></Box>
                        <Text>Jobs</Text>
                    </Flex>
                </Flex>
                  </Card>
              </Box>
          </Flex>
          <Box mt="10px"display='flex' flexDirection={{base:"column",md:"initial"}}>
              <Box flex={2} h={{base:"20vh",xl:"20vh",md:"16vh"}} mr='10px'mb={{base:"10px",md:0}}>
                  <Card h="inherit" p="10px" display='flex' justifyContent='center' alignItems='center'>
                      <Text>Number of Job posted</Text>
                        <Text>{dashboarInfo.numberOfPostedJobs}</Text>
                  </Card>
              </Box>
              <Box flex={2} h={{base:"20vh",xl:"20vh",md:"16vh",}} mr='10px'mb={{base:"10px",md:0}}>
                  <Card h="inherit" p="10px" display='flex' justifyContent='center' alignItems='center'>
                      <Text>Number of Unread Application</Text>
                      <Text>{dashboarInfo.numberOfAppliedJobs}</Text>
                  </Card>
              </Box>
              <Box flex={2} h={{base:"20vh",xl:"20vh" ,md:"16vh"}} mr='10px'mb={{base:"30px",md:0}}>
                  <Card h="inherit" p="10px" display='flex' justifyContent='center' alignItems='center'>
                      <Text>Number of Accepted applicant</Text>
                      <Text>{dashboarInfo.numberOfAcceptedJobs}</Text>
                  </Card>
              </Box>
          </Box>
    </Box>
  )
}

export default Graph
