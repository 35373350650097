import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  Interns, InternshipInterface } from "./model/intershipInterface";
import { getInterns } from "./services/internServices";
import { Await } from "react-router-dom";


const initialState:InternshipInterface={
    // interns: FakeInterns,
    interns: [],

    isLoading: null,
    isError: null,
    isSuccess: null,
    active: {
        name: "",
        email: ""
    }
}

export const getInternsAction = createAsyncThunk(
    "interns",
    async()=>{
        
         const response = await getInterns()
    
        
         return response.data
    }
)
 
const InternshipSlice = createSlice({
        name:"interns",
        initialState,
        reducers:{

            changeActiveIntern(state,action){
                state.active=action.payload
            }
        },

        extraReducers(builder) {
            builder.addCase(getInternsAction.fulfilled,(state,action)=>{
                state.interns= action.payload
                state.isSuccess=true
               
            })
            .addCase(getInternsAction.pending,(state)=>{
                state.isLoading=true
            })
            .addCase(getInternsAction.rejected,(state)=>{
                state.isError=true
            })
        },
})

export const InternshipAction=  InternshipSlice.actions
export default InternshipSlice.reducer